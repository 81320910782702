import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useFetch = () => {
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);

    const typeOfProperty = useSelector((state) => state);

    useEffect(() => {
        async function fetchData() {
if (typeOfProperty === "primaryMersin") {
                try {
                    const json = await fetch("/DB.json");
                    const res = await json.json();
                    setResponse(res);
                } catch (err) {
                    setError(err);
                }
}
if (typeOfProperty === "primaryAlanya") {
    try {
        const json = await fetch("/DBAlanyaPrimary.json");
        const res = await json.json();
        setResponse(res);
    } catch (err) {
        setError(err);
    }
}
        }
        fetchData();
    }, [typeOfProperty]);

    return { response, error };
};
