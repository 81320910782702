import React, { useEffect, useState } from "react";
import Preloader from "./Preloader";

export default function Weather() {
    const [weather, setWeather] = useState("");
    const api = {
        key: "70c73f32b2163012cab1521192c68b25",
        base: "https://api.openweathermap.org/data/2.5/",
    };

    useEffect(() => {
        function fetchData() {
            fetch(
                `${api.base}weather?lat=36.812103&lon=34.641479&lang=ru&appid=${api.key}`,
            )
                .then((res) => res.json())
                .then((result) => setWeather(result));
        }
        fetchData();
    }, [api.base, api.key]);

    return (
        <div className='footer_col'>
            <h4>Погода в Мерсине</h4>
            {weather && (
                <ul>
                    <li>
                        <div>
                            Температура: {Math.round(weather.main.temp - 273)}°C
                        </div>
                    </li>
                    <li>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "center",
                            }}
                        >
                            <img
                                src={`https://openweathermap.org/img/wn/${weather.weather[0].icon}@2x.png`}
                                alt='weatherIcon'
                            />
                            {weather.weather[0].description}
                        </div>
                    </li>
                </ul>
            )}
        </div>
    );
}
