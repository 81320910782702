import { ALANYA, MERSIN } from "../constants/constants";

export const typeOfProperty = (state = "primaryMersin", action) => {
    if (action.type === ALANYA) {
        return (state = "primaryAlanya");
    }

    if (action.type === MERSIN) {
        return (state = "primaryMersin");
    } else {
        return state;
    }
};