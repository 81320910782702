import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useFetch } from "./Hooks/useFetch";
// import HousePage from "./Pages/HousePage";
// import MainPage from "./Pages/MainPage";
// import SaleListPage from "./Pages/SaleListPage";
import ScrollToTop from "./components/ScrollToTop";
// import NotFound from "./Pages/NotFound";
import Footer from "./components/Footer";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import { Suspense, lazy, useEffect } from "react";
// ..
AOS.init({
    once: true,
});

const HousePage = lazy(() => import("./Pages/HousePage"));
const SaleListPage = lazy(() => import("./Pages/SaleListPage"));
const SaleListPageAlanya = lazy(() => import("./Pages/SaleListPageAlanya"));
const MainPage = lazy(() => import("./Pages/MainPage"));
const NotFound = lazy(() => import("./Pages/NotFound"));

function App() {
        const res = useFetch();
        const db = res.response;
        const error = res.error;

    return (
        <>
            <BrowserRouter>
                <ScrollToTop />

                <Routes>
                    <Route
                        path='/'
                        element={
                            <Suspense>
                                <MainPage />
                            </Suspense>
                        }
                    />
                    <Route
                        path='/houses/:id'
                        element={
                            <Suspense>
                                <HousePage db={db} error={error} />
                            </Suspense>
                        }
                    />
                    <Route
                        path='/salelist'
                        element={
                            <Suspense>
                                <SaleListPage db={db} error={error} />
                            </Suspense>
                        }
                    />
                    <Route
                        path='/salelistAlanyaPrimary'
                        element={
                            <Suspense>
                                <SaleListPageAlanya db={db} error={error} />
                            </Suspense>
                        }
                    />
                    <Route
                        path='*'
                        element={
                            <Suspense>
                                <NotFound />
                            </Suspense>
                        }
                    />
                </Routes>
                <Footer />
            </BrowserRouter>
        </>
    );
}

export default App;
