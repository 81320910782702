import React from "react";
import Weather from "./Weather";

export default function Footer() {
    return (
        <footer className='footer'>
            <div className='footer_container'>
                <div className='footer_row'>
                    <div className='footer_col'>
                        <h4>Навигация</h4>
                        <ul>
                            <li>
                                <a href='#up'>В начало страницы</a>
                            </li>
                            <li>
                                <a href='/'>Главная</a>
                            </li>
                            <li>
                                <a href='/salelist'>Каталог Мерсина</a>
                            </li>
                            <li>
                                <a href='/salelistAlanyaPrimary'>Каталог Алании</a>
                            </li>

                            {/* <li>
                                <a href='#'>affiliate program</a>
                            </li> */}
                        </ul>
                    </div>
                    <Weather />

                    <div className='footer_col'>
                        <h4>Контакты</h4>
                        <div className='footer_phone'>
                            <a href='tel:+90 (533) 329 92 67'>
                                +90 (533) 329 92 67
                            </a>
                        </div>
                        <div className='footer_social_links'>
                            <a
                                href='https://t.me/+905333299267'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                <img src='/telegram.svg' alt='telegram' />
                            </a>
                            <a
                                href='https://wa.me/905333299267'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                <img src='/whatsApp.svg' alt='whatsApp' />
                            </a>
                            <a
                                href='https://viber.click/79905333299267'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                <img src='/viber.svg' alt='viber' />
                            </a>
                        </div>
                    </div>
                    {/* <div className='footer_col'>
                        <h4>Помощь</h4>
                        <ul>
                            <li>
                                <a href='#'>FAQ</a>
                            </li>
                            <li>
                                <a href='#'>shipping</a>
                            </li>
                            <li>
                                <a href='#'>returns</a>
                            </li>
                            <li>
                                <a href='#'>order status</a>
                            </li>
                            <li>
                                <a href='#'>payment options</a>
                            </li>
                        </ul>
                    </div> */}
                </div>
            </div>
        </footer>
    );
}
