import React, { useState } from "react";
import RingLoader from "react-spinners/RingLoader";
import { css } from "@emotion/react";

export default function Preloader() {
    let [loading] = useState(true);

    const override = css`
        display: block;
        margin: 0 auto;
        margin-top: 150px;
    `;
    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div className='sweet-loading'>
                <RingLoader
                    color='#ffd700'
                    css={override}
                    loading={loading}
                    size={50}
                />
            </div>
        </div>
    );
}
